import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "*",
    redirect: "/404",
  },
  {
    path: "/404",
    name: "404",
    component: () => import("../views/404.vue"),
  },
  {
    path: "/error",
    name: "error",
    component: () => import("../views/error.vue"),
  },
  {
    path: "/",
    name: "home",
    component: () => import("../views/HomeView.vue"),
  },
  {
    path: "/gear",
    name: "gearlist",
    component: () => import("../views/GearListView.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/gear/:id",
    name: "gear",
    component: () => import("../views/GearView.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/gearcategory",
    name: "gearcategory",
    component: () => import("../views/GearCategoryView.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/gearchecklist",
    name: "gearchecklistlist",
    component: () => import("../views/GearChecklistListView.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/gearchecklist/:id",
    name: "gearchecklist",
    component: () => import("../views/GearChecklistView.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/plan",
    name: "planlist",
    component: () => import("../views/PlanListView.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/plan/:id",
    name: "plan",
    component: () => import("../views/PlanView.vue"),
    meta: { requiresAuth: true },
  },
  // {
  //   path: "/signup",
  //   name: "signup",
  //   component: () => import("../views/SignupView.vue"),
  // },
  // {
  //   path: "/emailverified/:id",
  //   name: "emailverified",
  //   component: () => import("../views/EmailVerifiedView.vue"),
  // },
  {
    path: "/emailverified",
    name: "emailverified",
    component: () => import("../views/EmailVerifiedView.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/LoginView.vue"),
  },
  {
    path: "/account/:id",
    name: "account",
    component: () => import("../views/AccountView.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/blog",
    name: "bloglistview",
    component: () => import("../views/BlogListView.vue"),
  },
  {
    path: "/youtuber",
    name: "youtuberlistview",
    component: () => import("../views/YoutuberListView.vue"),
  },
  {
    path: "/media",
    name: "medialistview",
    component: () => import("../views/MediaListView.vue"),
  },
  {
    path: "/link",
    name: "linklistview",
    component: () => import("../views/LinkListView.vue"),
  },
  {
    path: "/m",
    name: "m",
    component: () => import("../views/MView.vue"),
    meta: { requiresAuth: true },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!localStorage.getItem("isLoggedIn")) {
      next({
        path: "/login",
        query: {
          redirect: to.fullPath,
          message: true,
        },
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;

// export default router;
