import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import axios from "axios";
import CaseConversion from "@/tools/caseConversion";
import VueGtag from "vue-gtag";
import UrlFormat from "@/tools/urlFormat";

axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;

// ログインしていたらローカルストレージからtokenを設定
if (JSON.parse(localStorage.getItem("isLoggedIn"))) {
  axios.defaults.headers.common = {
    Authorization: `Bearer ${
      JSON.parse(localStorage.getItem("userData")).userAuthToken
    }`,
  };
}

Vue.prototype.$axios = axios;

// GA4の設定
Vue.use(VueGtag, {
  config: {
    id: process.env.VUE_APP_GA4_ID,
    params: {
      send_page_view: false,
    },
  },
});

// キャメルケースとスネークケースを変換する
Vue.prototype.$caseConversion = CaseConversion;

// 環境ごとのベースURL
Vue.prototype.$urlFormat = UrlFormat;

Vue.config.productionTip = false;

axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    switch (error.response?.status) {
      case 401:
        // 認証エラー系
        localStorage.clear();
        router.push({
          path: "/error",
        });
        break;
      case 422:
        localStorage.clear();
        router.push({
          path: "/error",
        });
        break;
      default:
        // 500エラーはここ
        localStorage.clear();
        router.push({
          path: "/error",
        });
        break;
    }
  }
);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
