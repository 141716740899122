// スネークケースからキャメルケースに変換（文字列）
function toCamelCase(str) {
  return str
    .split("_")
    .map(function (word, index) {
      if (index === 0) {
        return word.toLowerCase();
      }
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join("");
}

// スネークケースからキャメルケースに変換（オブジェクト）
function toCamelCaseObject(obj) {
  const result = {};
  Object.keys(obj).forEach((key) => {
    // 値が更に配列だったら処理する
    if (Array.isArray(obj[key])) {
      result[toCamelCase(key)] = toCamelCaseArray(obj[key]);
    } else {
      result[toCamelCase(key)] = obj[key];
    }
  });
  return result;
}

// スネークケースからキャメルケースに変換（配列）
function toCamelCaseArray(array) {
  let result = [];
  array.forEach((obj) => {
    result.push(toCamelCaseObject(obj));
  });
  return result;
}

// スネークケースからキャメルケースに変換（オブジェクト）(お役立ち記事のObjectに必要（妥協）)
function toCamelCaseObject2(obj) {
  const result = {};
  Object.keys(obj).forEach((key) => {
    // 値が更に配列だったら処理する
    if (Array.isArray(obj[key])) {
      result[toCamelCase(key)] = obj[key];
    } else {
      result[toCamelCase(key)] = obj[key];
    }
  });
  return result;
}

// スネークケースからキャメルケースに変換（配列）(お役立ち記事のObjectに必要（妥協）)
function toCamelCaseArray2(array) {
  let result = [];
  array.forEach((obj) => {
    result.push(toCamelCaseObject2(obj));
  });
  return result;
}

// キャメルケースからスネークケースに変換（文字列）.
function toUnderscoreCase(str) {
  return str
    .split(/(?=[A-Z])/)
    .join("_")
    .toLowerCase();
}

// キャメルケースからスネークケースに変換（オブジェクト）.
function toUnderscoreCaseObject(obj) {
  const result = {};
  Object.keys(obj).forEach((key) => {
    result[toUnderscoreCase(key)] = obj[key];
  });
  return result;
}

// 関数をエクスポートします。
export default {
  toCamelCaseObject,
  toUnderscoreCaseObject,
  toCamelCaseArray,
  toCamelCaseArray2,
};
