<template>
  <v-app>
    <!-- ヘッダーはPCのときだけ表示 -->
    <v-app-bar v-if="!isSmartPhone()" app color="#ffffff" elevation="0">
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      <v-btn v-if="userData" class="mr-2" icon to="/">
        <v-icon>mdi-information-outline</v-icon>
      </v-btn>

      <v-btn v-else class="mr-2 font-weight-black" rounded outlined to="/">
        キャンメモとは？
      </v-btn>

      <v-btn
        v-if="userData"
        class="mr-2 font-weight-black"
        style="text-transform: none"
        text
        rounded
        outlined
        :to="'/account/' + userData.userId"
      >
        <v-icon>mdi-account</v-icon>
        {{ userData.userName.substr(0, 8)
        }}<span v-if="userData.userName.length > 8">...</span>
      </v-btn>
    </v-app-bar>

    <v-navigation-drawer
      app
      v-model="drawer"
      width="300"
      style="background-color: #2d3447"
      dark
      :touchless="true"
    >
      <CloseMessage />
      <v-list-item style="cursor: pointer">
        <v-list-item-content>
          <v-img
            @click="goHomePage()"
            contain
            :src="$urlFormat.staticUrlFormat('other/logo_label_04.png')"
          ></v-img>
        </v-list-item-content>
      </v-list-item>

      <br />

      <v-list nav shaped>
        <v-list-item v-if="!userData" to="/login" link>
          <v-list-item-icon>
            <v-icon>mdi-login</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="font-weight-black"
              >ログイン</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>

        <v-list-item to="/gear" link>
          <v-list-item-icon>
            <v-icon>mdi-view-dashboard</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="font-weight-black"
              >ギア</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>

        <v-list-item to="/gearchecklist" link>
          <v-list-item-icon>
            <v-icon>mdi-format-list-checks</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="font-weight-black"
              >チェックリスト</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>

        <v-list-item to="/plan" link>
          <v-list-item-icon>
            <v-icon>mdi-calendar-multiple-check</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="font-weight-black"
              >記録・プランニング</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>

        <!-- <v-list-item to="/link" link>
          <v-list-item-icon>
            <v-icon>mdi-apps</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="font-weight-black"
              >お役立ち情報</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item> -->

        <v-list-item v-if="userData" :to="'/account/' + userData.userId" link>
          <v-list-item-icon>
            <v-icon>mdi-account</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="font-weight-black"
              >アカウント</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>

        <!-- キャンメモとは -->
        <v-list-item to="/" link>
          <v-list-item-icon>
            <v-icon>mdi-information-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="font-weight-black"
              >キャンメモとは？</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-footer v-bind="localAttrs" padless class="pt-2">
        <v-row justify="center" no-gutters>
          <v-btn
            color="white"
            text
            rounded
            small
            href="https://burnt-pest-f1b.notion.site/9f9e7b8fe1a14fb5a378a104ecd43692"
            target="_blank"
          >
            利用規約
          </v-btn>
          <v-btn
            color="white"
            text
            rounded
            small
            href="https://burnt-pest-f1b.notion.site/0316df76c6a24e82b126873afe09047e"
            target="_blank"
          >
            プライバシーポリシー
          </v-btn>
          <v-btn
            color="white"
            text
            rounded
            small
            href="https://burnt-pest-f1b.notion.site/391284596b124244bbfd739b49bd31b0"
            target="_blank"
          >
            運営情報
          </v-btn>
          <v-btn
            color="white"
            text
            rounded
            small
            href="https://forms.gle/EcwZcU92twT2Uz4P7"
            target="_blank"
          >
            お問い合わせ
          </v-btn>
          <v-btn
            color="white"
            style="text-transform: lowercase"
            text
            rounded
            small
            href="https://note.com/cammemo"
            target="_blank"
          >
            note.com
          </v-btn>

          <v-col class="pb-2 text-center white--text" cols="12">
            <v-divider class="mx-2"></v-divider>
            ©{{ new Date().getFullYear() }} - cammmemo.com
          </v-col>
          <div class="my-6 hidden-md-and-up">&nbsp;</div>
        </v-row>
      </v-footer>
    </v-navigation-drawer>

    <v-bottom-navigation
      v-if="isSmartPhone()"
      app
      grow
      background-color="#2d3447"
      dark
      value="gear"
      height="68"
    >
      <!-- ログイン -->
      <v-btn v-if="!userData" to="/login">
        <span style="font-size: 8px; font-weight: bold">ログイン</span>
        <v-icon class="mb-1" dense>mdi-login</v-icon>
      </v-btn>

      <!-- ギア -->
      <v-btn to="/gear" value="gear">
        <span style="font-size: 8px; font-weight: bold">ギア</span>
        <v-icon class="mb-1" dense>mdi-view-dashboard</v-icon>
      </v-btn>

      <!-- チェックリスト -->
      <v-btn to="/gearchecklist">
        <span style="font-size: 8px; font-weight: bold">チェックリスト</span>
        <v-icon class="mb-1" dense>mdi-format-list-checks</v-icon>
      </v-btn>

      <!-- 記録・プランニング -->
      <v-btn to="/plan">
        <span style="font-size: 8px; font-weight: bold">記録・プラン</span>
        <v-icon class="mb-1" dense>mdi-calendar-multiple-check</v-icon>
      </v-btn>

      <!-- メニュー -->
      <v-btn @click="drawer = !drawer">
        <span style="font-size: 8px; font-weight: bold">メニュー</span>
        <v-icon class="mb-1" dense>mdi-menu</v-icon>
      </v-btn>
    </v-bottom-navigation>

    <v-main style="background-color: #e6f2ff">
      <!-- ローディングのダイアログ -->
      <v-dialog
        v-model="getProgressDialog.isShow"
        hide-overlay
        persistent
        width="300"
      >
        <v-card color="primary" dark class="py-2">
          <v-card-text class="font-weight-black">
            {{ getProgressDialog.message }}
            <v-progress-linear
              class="mt-4"
              indeterminate
              rounded
              color="white"
              height="8"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>

      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import CloseMessage from "@/components/CloseMessage.vue";

export default {
  name: "App",
  props: [],

  data() {
    return {
      localAttrs: {
        // fixed: true,
        absolute: true,
      },
      progressDialog: false,
      drawer: null,
    };
  },
  components: { CloseMessage },
  watch: {
    getProgressDialog(value) {
      return value;
    },
  },
  created() {
    if (JSON.parse(localStorage.getItem("isLoggedIn"))) {
      // ログインしているたら実行
      this.getUserData();
    }
  },
  computed: {
    userData() {
      return this.$store.getters.getterUserData;
    },
    getProgressDialog() {
      return this.$store.getters.getterProgressDialog;
    },
  },
  methods: {
    isSmartPhone() {
      if (window.innerWidth <= 1200) {
        return true;
      } else {
        return false;
      }
    },
    goHomePage() {
      this.$router.push({
        path: "/",
      });
    },
    getUserData() {
      this.$axios.get("user/get").then((response) => {
        this.$store.commit("setterUserData", response.data);
        this.gearCategoriesGet();
        this.gearsGet();
        this.gearChecklistsGet();
        this.plansGet();
      });
    },
    gearChecklistsGet() {
      this.$axios.get("gear_checklist/get_all").then((response) => {
        this.$store.commit("setterGearChecklists", response.data);
        return 0;
      });
    },
    gearsGet() {
      this.$axios.get("gear/get_all").then((response) => {
        this.$store.commit("setterGears", response.data);
        return 0;
      });
    },
    gearCategoriesGet() {
      this.$axios.get("gear_category/get_all").then((response) => {
        this.$store.commit("setterGearCategories", response.data);
        return 0;
      });
    },
    plansGet() {
      this.$axios.get("plan/get_all").then((response) => {
        this.$store.commit("setterPlans", response.data);
        return 0;
      });
    },
  },
};
</script>
