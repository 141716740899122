import Vue from "vue";
import Vuex from "vuex";
import CaseConversion from "@/tools/caseConversion";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    gearCategories: [],
    gears: [],
    gearChecklists: [],
    userData: null,
    plans: [],
    progressDialog: { isShow: false, message: "" },
  },
  getters: {
    getterGears: (state) => {
      return state.gears;
    },
    getterGearCategories: (state) => {
      return state.gearCategories;
    },
    getterGearChecklists: (state) => {
      return state.gearChecklists;
    },
    getterUserData: (state) => {
      return state.userData;
    },
    getterPlans: (state) => {
      return state.plans;
    },
    getterProgressDialog: (state) => {
      return state.progressDialog;
    },
  },
  mutations: {
    setterGears(state, gears) {
      state.gears = CaseConversion.toCamelCaseArray(gears);
    },
    setterGearCategories(state, gearCategories) {
      state.gearCategories = CaseConversion.toCamelCaseArray(gearCategories);
    },
    setterGearChecklists(state, gearChecklists) {
      state.gearChecklists = CaseConversion.toCamelCaseArray(gearChecklists);
    },
    setterUserData(state, userData) {
      localStorage.setItem(
        "userData",
        JSON.stringify(CaseConversion.toCamelCaseObject(userData))
      );
      state.userData = CaseConversion.toCamelCaseObject(userData);
    },
    setterPlans(state, plans) {
      state.plans = CaseConversion.toCamelCaseArray(plans);
    },
    setterProgressDialog(state, value) {
      state.progressDialog.isShow = value.isShow;
      state.progressDialog.message = value.message;
    },
  },
  schedules: {},
  modules: {},
});
