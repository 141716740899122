<template>
  <div class="CloseMessage">
    <!-- <v-btn @click="dialog = true"> Open Dialog {{ dialog }}</v-btn> -->

    <v-dialog v-model="dialog" width="600" persistent>
      <v-card class="rounded-xl elevation-8">
        <v-card-title>
          <h4 style="color: red">【重要】サービス終了のお知らせ</h4>
        </v-card-title>
        <v-card-subtitle>
          <div class="mt-4 font-weight-black">
            いつもキャンメモをご利用いただき誠にありがとうございます。
            突然のお知らせで大変恐縮ではございますが、
            <span style="color: red">2024年5月1日</span
            >を持ちましてサービズの提供を終了することになりました。
            今までサービスをご利用いただき誠にありがとうございます。
            大変ご迷惑をおかけいたしますが、何卒ご理解いただけますようお願い申し上げます。これまでのご利用誠にありがとうございました。
          </div>
        </v-card-subtitle>
        <v-card-actions>
          <v-spacer />
          <v-btn @click="readCloseMessage()" color="primary" outlined
            >閉じる</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "CloseMessage",

  data() {
    return {
      dialog: false,
    };
  },
  created() {
    const isReadCloseMessage = JSON.parse(
      localStorage.getItem("isReadCloseMessage")
    );

    if (!isReadCloseMessage) {
      this.dialog = true;
    }
  },
  methods: {
    readCloseMessage() {
      this.$gtag.event("click", {
        event_category: "readCloseMessage",
        event_title: "サービス終了のお知らせダイアログを閉じたボタン",
      });
      this.dialog = false;
      localStorage.setItem("isReadCloseMessage", true);
    },
  },
};
</script>
